.btn-menu {
    display: flex;
    background-color: #212529;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
}

.btn-menu:hover {
    background-color: #212529;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.btn-menu-item {

    width: 100%;
    justify-content: center;
    text-align: center;
}

.btn-menu-icon {
    position: absolute;
    right: 10px
}

.btn-close-banner {
    width: 22px;
    height: 22px;
    background-color: red;
    border-radius: 20px;
    position: absolute;
    color: white;
    cursor: pointer;
    top: 10px;
    right: 10px;
    z-index: 2000;
    text-align: center;
}