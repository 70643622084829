body {
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

img {
    width: 100%;
}

.floating-button {
    width: 68px;
    height: 68px;
    /* background-color: #d3a530; */
    border-radius: 50px;
    border: 3px solid white;
    position: fixed;
    right: 20px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: expand .8s infinite;
}

.floating-button-wa {
    width: 68px;
    height: 68px;
    /* background-color: #d3a530; */
    position: fixed;
    right: 20px;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: expand .8s infinite;

}

.floating-button-ig-left {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 35%;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: expand .8s infinite;

}

.floating-button-ig-right {
    width: 50px;
    height: 50px;
    position: fixed;
    left: 35%;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: expand .8s infinite;
}

.floating-button-tiktok {
    width: 50px;
    height: 50px;
    position: fixed;
    left: 48%;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
    animation: expand .8s infinite;
}

@media only screen and (max-width: 600px) {
    .floating-button-ig-left {
        left: 10%;
    }

    .floating-button-ig-right {
        left: 80%;
    }

    .floating-button-tiktok {
        left: 45%;
    }
}



@keyframes expand {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}


.floating-button-circle {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
}

.turn-circle {
    animation-name: turncircle;
    animation-duration: .8s;
    animation-fill-mode: both;

}

@keyframes turncircle {
    /* 0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-95deg);

    } */
}

.background-dark {
    background-color: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

.content-menu {
    width: 70%;
    /* height: 88%; */
    background-color: white;
    border-radius: 10px;
    padding-top: 10px;
    overflow-x: scroll;
    padding-bottom: 15px;
    display: flex;
}

.close-menu {
    width: 30px;
    height: 30px;
    background-color: gray;
    border-radius: 50px;
    position: absolute;
    right: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2000;
}

.content-menu h3 {
    text-align: center;

}

.list-menu {
    width: 70%;
    /* list-style-type: none; */
    margin: 0;
}

.list-menu li {
    padding-top: 10px;
    /* border-bottom: 1px solid #CCC; */
}

.hidden {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}


.main-container {
    background-image: url(../../images/cuadro.png);
}